.signinoverlay {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  height: 100vh;
  width: 100vw;
  position: fixed;
  color: white;
  align-items: stretch;
}
h1 {
  font-size: 2.5em;
}
.title {
  margin-left: 20px;
  margin-top: 10px;
}
.titlem {
  margin-bottom: 50px;
  display: none;
  color: rgb(5, 118, 224);
}
p {
  font-size: 1em;
  color: rgb(100, 100, 100);
  margin-bottom: 20px;
}
.sidebar {
  flex: 70%;
  flex-grow: 5;
  padding: 30px;
  overflow: auto;
  background: rgb(5, 118, 224);
}
.overlaybox {
  justify-content: center;
  align-items: center; 
  display: -ms-flexbox;
  display: -webkit-flex;
display: flex;
}
.signinside {
  flex: 30%;
  flex-grow: 1;
  height: 100vh;
  justify-content: center;
  align-items: center; 
  display: -ms-flexbox;
  display: -webkit-flex;
display: flex;

   -ms-flex-align: center;
-webkit-align-items: center;
   -webkit-box-align: center;

   align-items: center;
  padding: 40px;
  color: black;
  vertical-align: center;
}
.divider {
  height: 5px;
  margin-bottom: 10px;
  margin-top: 15px;
  width: 250px;
  display: block;
  position: relative;

  border-radius: 5px;
  background: rgb(202, 202, 202);
}
.exit {
  position: fixed;
  display: block;
  top: 15px;
  height: 40px;
  width: 40px;
  transition: all .25s ease-out;
  text-align: center;
  border-radius: 30px;
  font-size: 35px;
  line-height: 40px;
  font-weight: bolder;
  right: 15px;
  font-size: 1.5em;
  color: black;
  background: rgb(202, 202, 202);
}
.exit:hover {
  transform: scale(1.1);
}
.exit:active {
  transform: scale(.975);
}
.sigincont {
  text-align: left;
  margin-top: -20px;

}
.login {
  margin-top: -30px;
}
.signindesc {
  margin-top: -20px;
}
.googlesignin:hover {
  transform: scale(1.1);
}
.googlesignin:active {
  transform: scale(.975);
}
.button1:hover {
  transform: scale(1.1);
}
.button1:active {
  transform: scale(.975);
}
.emailsignin {
  margin-bottom: 20px;
}
.emailsignin:hover {
  transform: scale(1.1);
}
.emailsignin:active {
  transform: scale(.975);
}
input {
  height: 30px;
  font-size: 20px;
  display: block;
  width: 235px;
  padding: 5px;
  border-radius: 10px;
  border: 2px solid rgb(202, 202, 202);
  margin-bottom: 15px;
}
.warningchip {
  height: auto;
  min-height: 25px;
  padding: 10px;
  max-width: 500px;
  width: fit-content;
  border-radius: 15px;
  background: rgb(228, 67, 67);
  margin-left: auto;
  font-size: 25px;
  line-height: 25px;
  margin-right: auto;
  left: 0;
  right: 0;
  color: white;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  transition: all 1s ease-out;
  position: fixed;
  box-shadow: 0 5px 8px 0 rgba(0,0,0,0.2);
  bottom: -325px;
  z-index: 9999;
}
.warningchip:hover {
  transition: all 0.25s;
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2);
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.5);
  z-index: 9998;
}
.overlaycard {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 250px;
  background-color: white;
  border-radius: 15px;
  color: black;
  padding: 20px;
}
.appcont {
  top: 0;
  left: 0;
}
.appsidebar {
  flex: 25%;
  flex-grow: 1;
  height: 100vh;
  display: -ms-flexbox;
  display: -webkit-flex;
display: flex;
  padding: 40px;
  color: black;
}
.appmain {
  flex: 75%;
  flex-grow: 5;
  padding: 30px;
  overflow: scroll;
  background: rgb(5, 118, 224);
}
.profilecircle {
  width: 50px;
  height: 50px;
  display: inline-block;
  border-radius: 50%;
  background-color: rgb(5, 118, 224);
  float: right;
  transition: all .25s ease-out;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.profilecircle:hover {
  transform: scale(1.1);
}
.appheader {
  height: 70px;
  width: calc(95vw - 20px);
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 15px;
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2);
}
.appbar {
  height: 50px;
  margin-top: 10px;
  display: inline-block;
  margin-left: 30px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
.apps {
  display: inline-block;
  height: 30px;
  border-radius: 20px;
  margin-top: 15px;
  width: auto;
  font-size: 20px;
  transition: all .25s ease-out;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 30px;
  color: rgb(5, 118, 224);
  margin-right: 10px;
  margin-left: 10px;
  font-weight: 500;
}
.apps:hover {
  background-color: rgb(226, 226, 226);
  transform: scale(1.1);
}
.createbutton {
  margin-right: 20px;
  height: 50px;
  border-radius: 20px;
  font-size: 25px;
  transition: all .25s ease-out;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 600;
  margin-top: 10px;
  line-height: 50px;
  float: right;
  display: inline-block;
  color: white;
  background-color: rgb(5, 118, 224);

}
.createbutton:hover {
  transform: scale(1.1);
}
.appconts {
  width: calc(100vw - 80px);
  padding-left: 40px;
  padding-right: 40px;
}
h2 {
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 0px;
  color: rgb(53, 53, 53);
}
.actioncont {
  margin-top: 30px;
  height: auto;
  width: calc(100vw - 60px);
  padding-left: 20px;
}
.actioncardcont {
  height: 400px;
  overflow: scroll;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
.actioncard {
  height: 280px;
  width: 250px;
  text-align: left;
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2);
  margin-top: 50px;
  padding: 10px;
  transition: all .25s ease-out;
  display: inline-block;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 15px;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
}

.actioncard:hover {
  transform: scale(1.1);
}
.actioncard:active {
  transform: scale(.975);
}
button:hover {
  transform: scale(1.1);
}
button:active {
  transform: scale(.975);
}
.actioncard h2 {
  font-size: 25px;
  margin-top: 10px;
  margin-left: 10px;
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  color: rgb(53, 53, 53);
} 
.author {
  font-size: 20px;
  margin-top: -10px;
  margin-left: 10px;
  position: relative;
  margin-bottom: 20px;
} 
.preview {
  font-size: 15px;
  margin-left: 10px;
  margin-right: 10px;
  flex: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgb(53, 53, 53);
  text-decoration: none;

}
li {
  color: rgb(53, 53, 53);
  text-decoration: none;
}
.titleinput {
  height: 40px;
  font-size: 35px;
  outline: 0;
  margin-top: 40px;
  width: calc(100vw - 140px);
  max-width: 800px;
  display: inline-block;
  transition: all .25s ease-out;
  padding-bottom: 3px;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 0px;
  border-width: 0 0 3px;
  border-color: rgb(202, 202, 202);
}
.titleedit {
  height: 40px;
  font-size: 35px;
  outline: 0;
  margin-top: 40px;
  width: calc(100vw - 140px);
  max-width: 800px;
  display: inline-block;
  transition: all .25s ease-out;
  padding-bottom: 3px;
  padding-left: 0px;
  padding-right: 0px;
}
.titleinput:focus {
  border-color: rgb(5, 118, 224);
}
.codex-editor {
  margin-left: auto;
  margin-right: auto;
}
.mobilesave {
  display: none;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
  text-align: center; 
  align-items: center;
}

@media only screen and (max-width: 800px) {
  .actioncard {
    width: 35vw;
    height: 45vw;
  }
  .titlem {
    display: block;
  }
  .title {
    display: none;
  }
  .sidebar {
    display: none;
  }
}
@media only screen and (max-width: 1200px) {
  .sidepanelleft {
    background: green;
    flex: none;
    display: none;
  }
  .sidepanelright {
    background: blue;
    flex: none;
    display: none;
  }
  .headerbar {
    flex: none;
  }
  .mobilesave {
    display: block;
    display: relative;
  }
  .createcont {
    display: block;
  }
}
.createcont {
  display: flex;
}
.headerbar {
  width: calc(100vw - 120px);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding: 20px;
  position: relative;
  flex: 0 0 800px;
}
.sidepanelleft {
  flex: 1;
}
.sidepanelright {
  flex: 1;
}
.sidepanel {
  float: right;
  margin-right: 20px;
  margin-top: 40px;
  width: 250px;
  display: block;
  color: black;
  border-radius: 15px;
  padding: 20px;
  background-color: white;
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2);
}
.journals {
  display: inline-block;
}
.deletebutton {
  width: 30px;
  height: 30px;
  padding: 2.5px;
  border: none;
  outline: none;
  float: right;
  margin-top: 10px;
  color: rgb(190, 35, 35);
  margin-right: 10px;
  border-radius: 15px;
  transition: all .25s ease-out;
  font-size: 25px;
  display: block;
  text-align: center;
  vertical-align: center;
}
.deletebutton:hover {
  transform: scale(1.1);
  background-color: rgb(202, 202, 202);
}
.deletebutton:active {
  transform: scale(.975);
  background-color: rgb(202, 202, 202);
}
.gridlanpmyout {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center; 
  text-align: center;
}
.postcontainer {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 800px;
}

.loadmore {
  margin-bottom: 50px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  height: 40px;
  padding: 10px;
  color: white;
  width: fit-content;
  position: relative;
  font-size: 35px;
  display: block;
  line-height: 40px;
  border-radius: 15px;
  background-color: rgb(5, 118, 224);
  transition: all .25s ease-out;
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2);

}
.loadmore:hover {
  transform: scale(1.1);
}
.loadmore:active {
  transform: scale(.975);
}
.commentsbox {
  margin-left: auto;
  margin-right: auto;
  width: calc(100vw - 60px);
  max-width: 740px;
  padding: 10px;
  margin-top: 40px;
  border-radius: 15px;
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2);

}
.comment{
  margin-left: auto;
  margin-right: auto;
  width: calc(100vw - 80px);
  max-width: 720px;
  padding: 10px;
  resize: none;
  min-height: 100px;
  height: fit-content;
  font: inherit;
  margin-top: 10px;
  border-radius: 15px;
  border: none;
}
.commentbutton {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  padding: 10px;
  margin-top: 10px;
  border-radius: 15px;
  border: none;
  background-color: rgb(5, 118, 224);
  color: white;
  font-size: 20px;
  display: block;
  line-height: 30px;
  margin-bottom: 20px;
  transition: all .25s ease-out;
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2);
}
.commentbutton:hover {
  transform: scale(1.1);
}
.commentbutton:active {
  transform: scale(.975);
}
.loadmorecomments {
  margin-bottom: 50px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  height: 20px;
  padding: 10px;
  color: white;
  width: fit-content;
  position: relative;
  font-size: 15px;
  display: block;
  line-height: 20px;
  border-radius: 15px;
  background-color: rgb(5, 118, 224);
  transition: all .25s ease-out;
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2);
}


